import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Unload extends Component {
    constructor(props) {
        super(props); 
        this.handleClose = this.handleClose.bind(this);
        this.keepOpen = this.keepOpen.bind(this);
    }
    
    componentDidMount() {
        window.addEventListener('unload', this.handleClose);
        window.addEventListener('beforeunload', this.keepOpen);
    }
    
    componentWillUnmount() {
        window.removeEventListener('unload', this.handleClose);
        window.removeEventListener('beforeunload', this.keepOpen);
    }
    
    handleClose = () => {
        this.props.setProps({ close: true });
    }
    
    keepOpen = (event) => {
        event.returnValue = 'Warning!\n\nYou will lose unsaved data if you navigate away from this page.';
        return event.returnValue
    }
    
    
    render() {
        return (null);
    }
}

Unload.defaultProps = {
    close: false,
    //text: 'Warning!\n\nYou will lose unsaved data if you navigate away from this page.'
};

Unload.propTypes = {
    //The ID used to identify this component in Dash callbacks
    id: PropTypes.string.isRequired,

    // The state of a window, to check if it is closed or still open
    close: PropTypes.bool,
    
    // Text that to be shown went browser is closed (does not work on all browsers)
    //text: PropTypes.string,

    // Dash-assigned callback that should be called whenever any of the properties change
    setProps: PropTypes.func
};
